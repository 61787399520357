import { Layout } from "providers/routing/layouts/templates/templates";
import { DASHBOARD } from "modules/site-generator/config/routes";
import { ModifyModulesFunction } from "providers/instance";
import Dashboard from "modules/panel/components/Dashboard/Dashboard";

const forsantInstanceModifier: ModifyModulesFunction = (modules) => {
  modules.smart.routes = modules.smart.routes
    .map((route) => {
      if (route.onboarding) route.layout = Layout.Blank;
      return route;
    });

  modules.siteGenerator.routes = modules.siteGenerator.routes.map((route) => {
    if (route.key === DASHBOARD) route.content = Dashboard;
    return route;
  });

  return modules;
};

export default forsantInstanceModifier;
