import carservice from "instances/carservice";
import forsant from "instances/forsant";

const Instances = {
  carservice,
  forsant,
};

export type Instance = keyof typeof Instances;

export default Instances;
