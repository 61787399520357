import { Layout } from "providers/routing/layouts/templates/templates";
import { ModifyModulesFunction } from "providers/instance";

const carserviceInstanceModifier: ModifyModulesFunction = (modules) => {
  modules.smart.routes = modules.smart.routes.map((route) => {
    if (route.onboarding) route.layout = Layout.Blank;
    return route;
  });

  return modules;
};

export default carserviceInstanceModifier;
